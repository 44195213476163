.locl-auth-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__form,
  &__footer {
    width: 100%;
    max-width: 611px;
  }

  &__form {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.22);
    padding: 32px;
    border-radius: 4px;
    background: var(--white-color-500);
    
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__footer {
    :global {
      .locl-auth-login-form-footer {
        padding: 12px 32px;
  
        &__text {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .locl-auth-login {
    &__form,
    &__footer {
      width: 100%;
      max-width: none;
    }

    &__form {
      padding: 32px 24px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &__footer {
      margin: 0 12px;
      width: calc(100% - 24px);

      :global {
        .locl-auth-login-form-footer {
          padding: 12px 24px;
        }
      }
    }
  }
}
